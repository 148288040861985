import { useEffect, useRef } from "react";
import store, { addMessage } from "../redux/maxStore";

const WebSocketClient = () => {
  const socketRef: any = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      const socket = new WebSocket("ws://localhost:8081");
      socketRef.current = socket;
      socket.addEventListener("open", () => {});
      socket.addEventListener("message", (event) => {
        const message = JSON.parse(event.data);
        store.dispatch(addMessage(message));
      });
      socket.addEventListener("close", () => {});
      return () => {
        socket.close();
      };
    }
  }, []);

  interface Message {
    content: string;
    channel: string;
    sender: {
      name: string;
    };
  }
  const sendMessage = (message: Message) => {
    const socket = socketRef.current;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.error(
        "WebSocket connection not open, message not sent:",
        message
      );

      socketRef.current = new WebSocket("ws://localhost:3333");
    }
  };

  return { sendMessage };
};

export default WebSocketClient;
