import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.scss";
import ProtectedRoute from "./ProtectedRoutes"; // Import the ProtectedRoute
import {
  Account,
  Chat,
  LandingPage,
  Main,
  Passwords,
  Projects,
  Settings,
  Sidebar,
  SignIn,
  Tasks,
} from "./components/index";
import store, { setIsAuthenticated } from "./redux/maxStore";
import WebSocketClient from "./sockets/socket";
import { Button } from "@material-ui/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const SidebarWrapper = ({
  isAuthenticated,
  sidebarOpen,
  setSidebarOpen,
}: any) => {
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      {isAuthenticated && (
        <>
          <button className="burger-menu" onClick={toggleSidebar}>
            &#9776; {/* Burger menu icon */}
          </button>
          <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
        </>
      )}
    </>
  );
};
const MainWrapper = () => {
  const { sendMessage } = WebSocketClient();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [landed, setLanded] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  store.subscribe(() => {
    const state = store.getState();
    const token = localStorage.getItem("token");
    if (token === "guest_token") {
      setIsGuest(true);
    } else {
      setIsGuest(false);
    }
    setAuthenticated(state.isConnected);
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      store.dispatch(setIsAuthenticated(true));
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLanded(true);
    }, 10000);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        height: "100%",
        position: "relative",
      }}
    >
      <>
        <BrowserRouter>
          {!landed && !isAuthenticated ? (
            <LandingPage setLanded={setLanded} />
          ) : (
            <>
              <ToastContainer theme="dark" />
              <SidebarWrapper
                isAuthenticated={isAuthenticated}
                setSidebarOpen={setSidebarOpen}
                sidebarOpen={sidebarOpen}
              />
              <Main />
              {!sidebarOpen && (
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <>
                        {!isAuthenticated ? (
                          <SignIn />
                        ) : (
                          <Navigate to="/chat" />
                        )}
                      </>
                    }
                  />
                  <Route
                    path="/chat"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Chat sendMessage={sendMessage} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      isAuthenticated || isGuest ? (
                        <Navigate to="/chat" />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                  <Route
                    path="/tasks"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated && !isGuest}
                      >
                        <Tasks />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/projects"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Projects />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/managePasswords"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated && !isGuest}
                      >
                        <Passwords />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated && !isGuest}
                      >
                        <Account />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated && !isGuest}
                      >
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              )}
            </>
          )}
        </BrowserRouter>
      </>
    </div>
  );
};

root.render(
  <Provider store={store}>
    <MainWrapper />
  </Provider>
);
