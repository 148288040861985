interface BasicDateTimePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const BasicDateTimePicker: React.FC<BasicDateTimePickerProps> = ({
  value,
  onChange,
}) => {
  // Component logic here
  return (
    <input
      className="form-control"
      type="datetime-local"
      value={value ? value.toISOString().slice(0, 16) : ""}
      onChange={(e) =>
        onChange(e.target.value ? new Date(e.target.value) : null)
      }
    />
  );
};

export default BasicDateTimePicker;
