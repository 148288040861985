import { createSlice, PayloadAction, configureStore } from "@reduxjs/toolkit";

const ChatStore = createSlice({
  name: "chat",

  initialState: {
    channel: "",
    value: [],
    isAuthenticated: false,
    isConnected: !!localStorage.getItem("token"),
    username: localStorage.getItem("username") || "",
  },
  reducers: {
    addMessage: (state: any, action: PayloadAction<any>) => {
      state.value.push(action.payload);
    },

    setIsAuthenticated: (state: any, action: PayloadAction<any>) => {
      state.isAuthenticated = action.payload;
    },

    setUserName: (state, action: PayloadAction<string>) => {
      state.isConnected = !!localStorage.getItem("token");
      localStorage.setItem("username", action.payload);
      state.username = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
  },
});

export const { setIsConnected, addMessage, setUserName, setIsAuthenticated } =
  ChatStore.actions;

const store = configureStore({
  reducer: ChatStore.reducer,
});

export default store;
